import React, { useState, ReactNode } from "react";
import Cropper from "react-easy-crop";
import "../../routes/HostPage.css";
type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({ children, onClick }) => (
  <button className="large-button" onClick={onClick}>
    {children}
  </button>
);

type CardProps = {
  children: ReactNode;
};

const Card: React.FC<CardProps> = ({ children }) => (
  <div className="border p-4 rounded">{children}</div>
);

type SliderProps = {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Slider: React.FC<SliderProps> = ({ min, max, step, value, onChange }) => (
  <input
    type="range"
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={onChange}
    className="w-full"
    style={{ marginTop: 25, marginBottom: 25 }}
  />
);

type ImagePickerProps = {
  handleValidImageSelected: (file: File) => void;
};

const ImagePicker: React.FC<ImagePickerProps> = ({
  handleValidImageSelected,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result as string);
      };
    }
  };

  const handleCrop = async () => {
    if (!imageSrc || !croppedAreaPixels) return;
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const { width, height } = croppedAreaPixels;
    canvas.width = Math.max(width, 200);
    canvas.height = Math.max(height, 200);

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      width,
      height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    canvas.toBlob((blob) => {
      if (blob) {
        const croppedFile = new File([blob], "cropped_image.png", {
          type: "image/png",
        });
        handleValidImageSelected(croppedFile);
        setImageSrc(null);
      }
    }, "image/png");
  };

  return (
    <Card>
      <div className="image-picker">
        <label className="image-picker-label">
          Choose Image
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </label>
      </div>
      {imageSrc && (
        <div
          style={{
            position: "relative",
            width: 400,
            height: 400,
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "grey",
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
      )}
      {imageSrc && (
        <div>
          <Slider
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(Number(e.target.value))}
          />
          <Button onClick={handleCrop}>Crop & Save</Button>
        </div>
      )}
    </Card>
  );
};

async function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = "anonymous";
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
}

export default ImagePicker;
