import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";
import copy from "../assets/copy.png";
import { useApiAnalytics } from "../api/api";
import "./AnalyticsPage.css";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function AnalyticsPage() {
  const analytics = useApiAnalytics();

  const [totalPodcasts, setTotalPodcasts] = useState(0);
  const [avgPodcastDepth, setAvgPodcastDepth] = useState(0);
  const [deepestPodcast, setDeepestPodcast] = useState(0);
  const [totalSharedPodcasts, setTotalSharedPodcasts] = useState(0);
  const [percentSharedPodcasts, setPercentSharedPodcasts] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [avgCommentsPerPodcast, setAvgCommentsPerPodcast] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [avgLikesPerPodcast, setAvgLikesPerPodcast] = useState(0);
  const [avgReach, setAvgReach] = useState(0);

  const [totalUsers, setTotalUsers] = useState(0);
  const [avgPodcastsPerUser, setAvgPodcastsPerUser] = useState(0);
  const [avgPersonasPerUser, setAvgPersonasPerUser] = useState(0);

  const [totalFollows, setTotalFollows] = useState(0);
  const [avgFollowsPerUser, setAvgFollowsPerUser] = useState(0);

  const [totalElevenLabsUsage, setTotalElevenLabsUsage] = useState(0);
  const [avgElevenLabsUsage, setAvgElevenLabsUsage] = useState(0);

  type DataSet = {
    name: string;
    value: number;
  }[];
  const [podcastsPerDay, setPodcastsPerDay] = useState<DataSet>([]);
  const [podcastsPerPersona, setPodcastsPerPersona] = useState<DataSet>([]);
  const [uniqueUsersPerDay, setUniqueUsersPerDay] = useState<DataSet>([]);
  const [daysActiveDistribution, setDaysActiveDistribution] = useState<DataSet>(
    []
  );
  const [totalUsersCumulative, setTotalUsersCumulative] = useState<DataSet>([]);
  const [elevenLabsUsage, setElevenLabsUsage] = useState<DataSet>([]);

  useEffect(() => {
    if (
      !analytics ||
      !analytics.data ||
      !analytics.data?.analytics ||
      !analytics.data?.analytics.data
    ) {
      return;
    }

    const getDataSetIndexByName = (dataSet: DataSet, name: string) => {
      return dataSet.findIndex((data) => data.name === name);
    };

    const { podcasts, users, personas, elevenLabsUsage } =
      analytics.data.analytics.data;

    //Total Podcasts
    setTotalPodcasts(podcasts.length);

    //Avg Podcast depth (childpodcats count)
    const podcastDepths = podcasts.reduce((acc, podcast) => {
      return acc + 1 + podcast.Podcasts.length;
    }, 0);
    setAvgPodcastDepth(podcastDepths / podcasts.length);

    //Deepest podcast
    const deepestPodcast = podcasts.reduce((acc, podcast) => {
      return Math.max(acc, 1 + podcast.Podcasts.length);
    }, 0);
    setDeepestPodcast(deepestPodcast);

    //Shared Rabbitholes Total (%)
    const sharedPodcasts = podcasts.filter((podcast) => podcast.shareId);
    const totalSharedPodcasts = sharedPodcasts.length;
    setTotalSharedPodcasts(totalSharedPodcasts);
    setPercentSharedPodcasts(
      (totalSharedPodcasts / podcasts.length) * 100 || 0
    );

    //Comments Total (Avg)
    const totalComments = podcasts.reduce((acc, podcast) => {
      return acc + podcast.Comments.length;
    }, 0);
    setTotalComments(totalComments);
    setAvgCommentsPerPodcast(totalComments / podcasts.length);

    //Likes Total (Avg)
    const totalLikes = podcasts.reduce((acc, podcast) => {
      return acc + podcast.Likes.length;
    }, 0);
    setTotalLikes(totalLikes);
    setAvgLikesPerPodcast(totalLikes / podcasts.length);

    //Avg Reach
    const avgReach =
      sharedPodcasts.reduce((acc, podcast) => {
        return acc + podcast.UserProfile_Podcasts.length;
      }, 0) / totalSharedPodcasts;
    setAvgReach(avgReach);

    //Total Users
    setTotalUsers(users.length);

    //Avg Rabbitholes per User
    setAvgPodcastsPerUser(podcasts.length / users.length);

    //{userId: personaId[]}
    const personasPerUser = users
      .map((user) => {
        const userPersonas = podcasts
          .filter((podcast) => podcast.userProfileId === user.id)
          .map((podcast) => podcast.personaId)
          .filter(
            (personaId, index, self) => self.indexOf(personaId) === index
          );
        return userPersonas.length;
      })
      .reduce((acc, count) => acc + count, 0);
    //Avg Personas per User
    setAvgPersonasPerUser(personasPerUser / users.length);

    //Total Follows
    const follows = users.reduce((acc, user) => {
      return acc + user.Followees.length;
    }, 0);
    setTotalFollows(follows);
    //Avg follows per user
    setAvgFollowsPerUser(follows / users.length);

    const podcastsPerDay = [] as DataSet;
    const podcastsPerPersona = [] as DataSet;
    const totalUsersCulumative = [] as DataSet;
    const uniqueUsersPerDay = {} as { [key: string]: number[] };
    const daysActivePerUser = {} as { [key: number]: string[] };

    //helper functions
    //Make sure index exists, if not create it with initial value
    //Then apply newValue function to current value
    const calculateDataSet = (
      dataSet: DataSet,
      name: string,
      initialValue: number,
      newValue: (currentValue: number) => number
    ) => {
      const index = getDataSetIndexByName(dataSet, name);
      if (index === -1) {
        dataSet.push({ name, value: initialValue });
        return dataSet.length - 1;
      } else {
        dataSet[index].value = newValue(dataSet[index].value);
        return index;
      }
    };

    //Make sure key exists, if not create it with empty array
    //Then push value to array
    const calculateMap = <K extends string | number, V extends string | number>(
      map: { [key in K]: V[] },
      key: K,
      value: V
    ) => {
      if (!map[key]) {
        map[key] = [];
      }
      map[key].push(value);
    };

    // ** PODCASTS **
    podcasts.forEach((podcast) => {
      const date = new Date(podcast.createdAt).toDateString();

      //Podcasts per day
      calculateDataSet(podcastsPerDay, date, 1, (value) => value + 1);

      //Podcasts per persona
      calculateDataSet(
        podcastsPerPersona,
        podcast.personaId.toString(),
        1,
        (value) => value + 1
      );

      //Unique users per day
      calculateMap(uniqueUsersPerDay, date, podcast.userProfileId);

      //Days active per user
      calculateMap(daysActivePerUser, podcast.userProfileId, date);
    });

    // ** USERS **
    let totalUsersCumulativeCount = 0;
    users.forEach((user) => {
      totalUsersCumulativeCount++;
      const date = new Date(user.createdAt).toDateString();
      calculateDataSet(
        totalUsersCulumative,
        date,
        totalUsersCumulativeCount,
        () => totalUsersCumulativeCount
      );
    });

    //add empty dates to datasets. Either with value 0 or the previous value dates
    const padEmptyDates = (dataSet: DataSet, value: "0" | "prev" = "0") => {
      const dateRangeReversed = (startDate: Date, endDate: Date) => {
        const dates = [];
        let currentDate = new Date(endDate);
        while (currentDate >= startDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() - 1);
        }
        return dates;
      };

      for (let i = dataSet.length - 1; i > 0; i--) {
        const date = new Date(dataSet[i].name);
        const prevDate = new Date(dataSet[i - 1].name);
        const missingDates = dateRangeReversed(prevDate, date).slice(1, -1);

        missingDates.forEach((missingDate) => {
          dataSet.splice(i, 0, {
            name: missingDate.toDateString(),
            value: value === "0" ? 0 : dataSet[i - 1].value,
          });
        });
      }
      return dataSet;
    };

    //format dates to locale string
    const formatDates = (dataSet: DataSet) => {
      return dataSet.map((data) => {
        return {
          name: new Date(data.name).toLocaleDateString(),
          value: data.value,
        };
      });
    };
    //replace persona ids with names
    const formatPersonaNames = (dataSet: DataSet) => {
      return dataSet.map((data) => {
        return {
          name:
            personas.find((persona) => persona.id === parseInt(data.name))
              ?.name || "unknown",
          value: data.value,
        };
      });
    };
    //add missing dates & format dates
    const formatDateDataSet = (dataSet: DataSet, value: "0" | "prev" = "0") => {
      return formatDates(padEmptyDates(dataSet, value));
    };
    setPodcastsPerPersona(formatPersonaNames(podcastsPerPersona));
    setPodcastsPerDay(formatDateDataSet(podcastsPerDay, "0"));
    setTotalUsersCumulative(formatDateDataSet(totalUsersCulumative, "prev"));
    setUniqueUsersPerDay(
      formatDateDataSet(
        Object.entries(uniqueUsersPerDay).map(([date, users]) => {
          return { name: date, value: users.length };
        }),
        "0"
      )
    );

    //calculate days active distribution
    const calculateDaysActiveDistribution = (dataSet: {
      [key: number]: string[];
    }) => {
      const daysActiveDistribution = [] as DataSet;
      Object.entries(dataSet).forEach(([_, dates]) => {
        const daysActive = dates.length;
        calculateDataSet(
          daysActiveDistribution,
          daysActive.toString(),
          1,
          (value) => value + 1
        );
      });

      //Pad missing values with 0 to chart
      const maxDaysActive = Math.max(
        ...daysActiveDistribution.map((data) => parseInt(data.name))
      );
      for (let i = 1; i <= maxDaysActive; i++) {
        if (
          !daysActiveDistribution.find((data) => data.name === i.toString())
        ) {
          daysActiveDistribution.push({ name: i.toString(), value: 0 });
        }
      }

      return daysActiveDistribution.sort(
        (a, b) => parseInt(a.name) - parseInt(b.name)
      );
    };

    setDaysActiveDistribution(
      calculateDaysActiveDistribution(daysActivePerUser)
    );

    // ** ELEVENLABS **
    let elevenLabsUsageDataSet = [] as DataSet;
    let cumulativeElevenLabsUsage = 0;
    elevenLabsUsage.time.forEach((time, index) => {
      const date = new Date(time).toDateString();
      const usage = elevenLabsUsage.usage.All[index];
      cumulativeElevenLabsUsage += usage;
      calculateDataSet(elevenLabsUsageDataSet, date, usage, () => usage);
    });
    elevenLabsUsageDataSet = formatDateDataSet(elevenLabsUsageDataSet);
    setTotalElevenLabsUsage(cumulativeElevenLabsUsage);
    setAvgElevenLabsUsage(
      cumulativeElevenLabsUsage / elevenLabsUsageDataSet.length
    );
    setElevenLabsUsage(elevenLabsUsageDataSet);
  }, [analytics.data]);

  const chartGridOptions = {
    stroke: "#cccccc55",
    strokeDasharray: "1 5",
  };

  interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="label">{label}</div>
          <div className="value">{payload[0].value.toLocaleString()}</div>
        </div>
      );
    }
    return null;
  };

  const responsiveContainerOptions = {
    minHeight: 380,
  };

  return (
    <div className="app">
      <HeaderBar>
        <img
          src={copy}
          alt="Analytics"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          Analytics
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        ></div>
      </HeaderBar>
      <NavBar selectedIndex={3} />

      <div className="content" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div className="charts">
          <div>
            <table className="analytics-table">
              <tbody>
                <tr>
                  <td>Total Rabbitholes</td>
                  <td>{totalPodcasts}</td>
                </tr>
                <tr>
                  <td>Deepest Rabbithole (Avg)</td>
                  <td>
                    {deepestPodcast} ({Math.round(avgPodcastDepth)})
                  </td>
                </tr>
                <tr>
                  <td>Shared Rabbitholes Total (%)</td>
                  <td>
                    {totalSharedPodcasts} ({Math.round(percentSharedPodcasts)}%)
                  </td>
                </tr>
                <tr>
                  <td>Avg. Reach</td>
                  <td>{Math.round(avgReach)}</td>
                </tr>
                <tr>
                  <td>Comments Total (Avg)</td>
                  <td>
                    {totalComments} ({Math.round(avgCommentsPerPodcast)})
                  </td>
                </tr>
                <tr>
                  <td>Likes Total (Avg)</td>
                  <td>
                    {totalLikes} ({Math.round(avgLikesPerPodcast)})
                  </td>
                </tr>
                <tr>
                  <td>Total Users</td>
                  <td>{totalUsers}</td>
                </tr>
                <tr>
                  <td>Avg. Rabbitholes per User</td>
                  <td>{Math.round(avgPodcastsPerUser)}</td>
                </tr>
                <tr>
                  <td>Avg. Personas per User</td>
                  <td>{Math.round(avgPersonasPerUser)}</td>
                </tr>
                <tr>
                  <td>Follows Total (Avg)</td>
                  <td>
                    {totalFollows} ({Math.round(avgFollowsPerUser)})
                  </td>
                </tr>
                <tr>
                  <td>ElevenLabs Tokens Used (Avg per day)</td>
                  <td>
                    {totalElevenLabsUsage.toLocaleString()} (
                    {Math.round(avgElevenLabsUsage).toLocaleString()})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h3>Podcasts per Persona</h3>
            <ResponsiveContainer {...responsiveContainerOptions} height={600}>
              <BarChart data={podcastsPerPersona} layout="vertical">
                <Bar
                  type="monotone"
                  dataKey="value"
                  stroke="#f0ede0"
                  fill="#1c1c1c"
                />
                <CartesianGrid {...chartGridOptions} horizontal={false} />
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="name"
                  width={200}
                  interval={0}
                />
                <Tooltip content={<CustomTooltip />} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3>Podcasts per Date</h3>
            <ResponsiveContainer {...responsiveContainerOptions}>
              <LineChart data={podcastsPerDay}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#f0ede0"
                  dot={false}
                />
                <CartesianGrid {...chartGridOptions} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3>Unique Users per Date</h3>
            <ResponsiveContainer {...responsiveContainerOptions}>
              <LineChart data={uniqueUsersPerDay}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#f0ede0"
                  dot={false}
                />
                <CartesianGrid {...chartGridOptions} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3>Users Active # Days</h3>
            <ResponsiveContainer {...responsiveContainerOptions}>
              <BarChart data={daysActiveDistribution}>
                <Bar
                  type="monotone"
                  dataKey="value"
                  stroke="#f0ede0"
                  fill="#1c1c1c"
                />
                <CartesianGrid {...chartGridOptions} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3>Total Users Cumulative</h3>
            <ResponsiveContainer {...responsiveContainerOptions}>
              <LineChart data={totalUsersCumulative}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#f0ede0"
                  dot={false}
                />
                <CartesianGrid {...chartGridOptions} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3>ElevenLabs Token Usage</h3>
            <ResponsiveContainer {...responsiveContainerOptions}>
              <LineChart data={elevenLabsUsage}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#f0ede0"
                  dot={false}
                />
                <CartesianGrid {...chartGridOptions} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AnalyticsPage;
