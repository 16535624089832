import React, { useEffect, useRef, useState } from "react";
import { PersonaModel } from "../../../../model/persona";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { mapsApiKey } from "../../../../constants";
import { defaultCenter } from "../../player/hostPlayerMap/hostPlayerMap";
import LargeButton from "../../../input/LargeButton";
import { poiPGAugmentPOIsWithWebsearch, poiPGGenerateFollowUpSuggestions, poiPGGenerateStory, poiPGGooglePOIsInRadius } from "../../../../api/poi/poi";
import Select, { MultiValue } from "react-select";
import { dropdownStyles, OptionType, webSearchTopicOptions } from "../../../../routes/HostPage";
import MultiselectAccordionGooglePlacesTypes, { GooglePlacesTypeGroups } from "../../../input/MultiselectAccordionGooglePlacesTypes";
import Collapsible from "../../../layout/Collapsible";

interface HostPlaygroundSurpriseMeProps {
    host: PersonaModel;
}
export interface Location {
    lat: number;
    lng: number;
}

const HostPlaygroundSurpriseMe: React.FC<HostPlaygroundSurpriseMeProps> = ({ host }) => {
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const mapRef = useRef<google.maps.Map | null>(null);
    const handleLoad = (map: google.maps.Map) => {
        mapRef.current = map;
    };
    const handleCenterChanged = () => {
        // Check if mapRef is current and accessible
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
            setMapCenter({
                lat: newCenter?.lat() ?? mapCenter.lat,
                lng: newCenter?.lng() ?? mapCenter.lng,
            });
        }
    };
    const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error obtaining location:", error);
                    // Handle error or set a default location
                    setCurrentLocation(defaultCenter); // Default to New York City
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            // Set a default location
            setCurrentLocation(defaultCenter); // Default to New York City
        }
    }, []);

    useEffect(() => {
        const initialSelectedOptions = GooglePlacesTypeGroups.map(
            (g) => g.options
        )
            .flat()
            .filter((option) => host.poiTypes.includes(option.value));
        setSelectedPoiTypes(initialSelectedOptions);
    }, [host]);
    const [selectedPoiTypes, setSelectedPoiTypes] = useState<MultiValue<OptionType>>([]);
    const handlePoiTypesChange = (selected: MultiValue<OptionType>) => {
        setSelectedPoiTypes(selected);
    }

    const [googlePOIsResponse, setGooglePOIsResponse] = useState<any[] | null>(null);
    const [searchGooglePOIsButtonLoading, setSearchGooglePOIsButtonLoading] = useState(false);
    const searchGooglePOIs = async () => {
        const poisResponse = await poiPGGooglePOIsInRadius({ location: { latitude: mapCenter.lat, longitude: mapCenter.lng }, poiTypes: selectedPoiTypes.map((option) => option.value) });
        console.log(poisResponse);
        if (poisResponse && poisResponse.data && poisResponse.data.pois) {
            setGooglePOIsResponse(poisResponse.data.pois);
        }
    }
    const renderGooglePOIsResponse = () => {
        if (!googlePOIsResponse || !googlePOIsResponse.length) {
            return null;
        }
        return <div className="api-response">
            <h3>Google POIs Response</h3>
            {googlePOIsResponse.map((poi, key) => {
                return <div className="api-response-container" key={key}>
                    <h4>Name: {poi.name}</h4>
                    <p>POI Types: <span>{poi.types?.join(", ")}</span></p>
                    <p>Address: <span>{poi.address}</span></p>
                    <p>City: <span>{poi.city}</span></p>
                    <p>Country: <span>{poi.country}</span></p>
                    <p>Distance: <span>{poi.distance} meters away</span></p>
                    <p>Description: <span>{poi.description}</span></p>
                </div>
            })}
        </div>
    }

    useEffect(() => {
        const selectedWebsearchTopic = webSearchTopicOptions().find((option) => option.value === host.webSearchTopic);
        if (selectedWebsearchTopic) {
            setSelectedWebSearchTopic(selectedWebsearchTopic);
        } else {
            setSelectedWebSearchTopic(webSearchTopicOptions()[0]);
        }
    }, [host]);

    const [selectedWebSearchTopic, setSelectedWebSearchTopic] =
        useState<OptionType | null>();

    const originalWebSearchQueryTemplate = host.webSearchQueryTemplate || "Interesting facts about {poi.name}, {poi.address}";
    const [webSearchQuery, setWebSearchQuery] = useState(originalWebSearchQueryTemplate);
    const [includeDomains, setIncludeDomains] = useState<string[]>(host.webSearchIncludeDomains || []);
    const [excludeDomains, setExcludeDomains] = useState<string[]>(host.webSearchExcludeDomains || []);

    const [loadingWebsearch, setLoadingWebsearch] = useState(false);
    const [webSearchResponse, setWebSearchResponse] = useState<any[] | null>(null);
    const doWebsearch = async () => {
        if (!selectedWebSearchTopic || !googlePOIsResponse) {
            return;
        }
        const webSearch = await poiPGAugmentPOIsWithWebsearch({
            query: webSearchQuery,
            searchTopic: selectedWebSearchTopic.value,
            includeDomains,
            excludeDomains,
            pois: googlePOIsResponse
        })
        if (webSearch && webSearch.data && webSearch.data.pois) {
            setWebSearchResponse(webSearch.data.pois);
        }
    };
    const renderWebsearchResponse = () => {
        if (!webSearchResponse || !webSearchResponse.length) {
            return null;
        }
        return <div className="api-response">
            <h3>Augmented Google POIs with Websearch</h3>
            {webSearchResponse.map((poi, key) => {
                return <div className="api-response-container" key={key}>
                    <h4>Name: {poi.name}</h4>
                    <p>Original Description: <span>{poi.descriptionOriginal}</span></p>
                    <p>New Description: <span>{poi.description}</span></p>
                    <Collapsible buttonText="Sources">
                        {poi.results.map((result: any, index: number) => {
                            return <div key={index} style={{ marginBottom: 20 }}>
                                <a href={result.url} target="_blank" rel="noreferrer">{result.title}</a>
                                <p>{result.content}</p>
                            </div>
                        })}
                    </Collapsible>
                </div>
            })}
        </div>
    }

    const originalPromptSystemStory = host.promptTemplateHost;
    const [promptSystemStory, setPromptSystemStory] = useState(originalPromptSystemStory);
    const originalPromptUserStory = host.promptTemplatePoiMulti;
    const [promptUserStory, setPromptUserStory] = useState(originalPromptUserStory);

    const applyAugmentedPOIsToPromptsStory = () => {
        if (!webSearchResponse || !webSearchResponse.length) {
            return;
        }
        return augmentPOIstoPromptsStory(webSearchResponse);
    }

    const applyPOIstoPromptsStory = () => {
        if (!googlePOIsResponse || !googlePOIsResponse.length) {
            return;
        }
        return augmentPOIstoPromptsStory(googlePOIsResponse);
    }

    const augmentPOIstoPromptsStory = (data: any[]) => {
        if (!data || !data.length) {
            return;
        }
        const poi = data[0];
        const pois = data.map(poi => { return `# ${poi.name}\n\n${poi.address}, ${poi.distance} meters away.\n\n${poi.description}\n---\n\n`; }).join("");

        let _promptSystem = originalPromptSystemStory || "";
        _promptSystem = _promptSystem.replaceAll(/{city}/g, poi.city || "");
        _promptSystem = _promptSystem.replaceAll(/{country}/g, poi.country || "");
        _promptSystem = _promptSystem.replaceAll(/{pois}/g, pois || "");
        setPromptSystemStory(_promptSystem);

        let _promptUser = originalPromptUserStory || "";
        _promptUser = _promptUser.replaceAll(/{pois}/g, pois || "");
        setPromptUserStory(_promptUser);
    }

    const [generateStoryButtonLoading, setGenerateStoryButtonLoading] = useState(false);
    const [storyResponse, setStoryResponse] = useState<any | null>(null);
    const doGenerateStory = async () => {
        if (!promptSystemStory || !promptUserStory) {
            return;
        }
        const story = await poiPGGenerateStory({ promptSystem: promptSystemStory, promptUser: promptUserStory });
        if (story && story.data?.story) {
            setStoryResponse(story.data.story);
        }
    }
    const renderStoryResponse = () => {
        if (!storyResponse) {
            return null;
        }
        return <div className="api-response">
            <h4>Story Response</h4>
            <div className="api-response-container">
                <p className="story-response">{storyResponse}</p>
            </div>
        </div>
    }

    const originalPromptSystemFollowUpSuggestions = host.promptTemplateFollowUpSuggestions;
    const [promptSystemFollowUpSuggestions, setPromptSystemFollowUpSuggestions] = useState(originalPromptSystemFollowUpSuggestions);
    const originalPromptUserFollowUpSuggestions = "";
    const [promptUserFollowUpSuggestions, setPromptUserFollowUpSuggestions] = useState(originalPromptUserFollowUpSuggestions);
    const applyStoryToPromptsFollowUpSuggestions = () => {
        if (!storyResponse) {
            return;
        }
        setPromptUserFollowUpSuggestions(storyResponse);
    }
    const [generateFollowUpSuggestionsButtonLoading, setGenerateFollowUpSuggestionsButtonLoading] = useState(false);
    const [followUpSuggestionsResponse, setFollowUpSuggestionsResponse] = useState<any[] | null>(null);
    const doGenerateFollowUpSuggestions = async () => {
        if (!promptSystemFollowUpSuggestions || !promptUserFollowUpSuggestions) {
            return;
        }

        const followUpSuggestions = await poiPGGenerateFollowUpSuggestions({ promptSystem: promptSystemFollowUpSuggestions, promptUser: promptUserFollowUpSuggestions });
        if (followUpSuggestions && followUpSuggestions.data?.followUpSuggestions) {
            setFollowUpSuggestionsResponse(followUpSuggestions.data.followUpSuggestions);
        }

    }
    const renderFollowUpSuggestionsResponse = () => {
        if (!followUpSuggestionsResponse) {
            return;
        }
        return <div className="api-response">
            <h4>Follow Up Suggestions Response</h4>
            <div className="api-response-container">
                {followUpSuggestionsResponse.map((suggestion: any, index: number) => {
                    return <div key={index}>
                        <p>{index + 1}: <span>{suggestion}</span></p>
                    </div>
                })}
            </div>
        </div>
    }

    useEffect(() => {
        if (!storyResponse) {
            return;
        }
        const followUpStory = storyResponse;
        setPromptSystemFollowUpStory(followUpStory);
    }, [storyResponse])
    const [promptSystemFollowUpStory, setPromptSystemFollowUpStory] = useState("");
    const originalPromptUserFollowUpStory = host.promptTemplateFollowUp || "Now, let's dive deeper into the story of {follow_up_topic}.\n\nWhat are some interesting details about this topic that we haven't covered yet?";
    const [promptUserFollowUpStory, setPromptUserFollowUpStory] = useState(originalPromptUserFollowUpStory);
    const applyFollowUpSuggestionToPrompts = (suggestion: string) => {
        let _promptUser = originalPromptUserFollowUpStory || "";
        _promptUser = _promptUser.replaceAll(/{follow_up_topic}/g, suggestion);
        setPromptUserFollowUpStory(_promptUser);
    }
    const [generateFollowUpStoryButtonLoading, setGenerateFollowUpStoryButtonLoading] = useState(false);
    const [followUpStoryResponse, setFollowUpStoryResponse] = useState<any | null>(null);
    const doGenerateFollowUpStory = async () => {
        if (!promptSystemFollowUpStory || !promptUserFollowUpStory) {
            return;
        }
        const followUpStory = await poiPGGenerateStory({ promptSystem: promptSystemFollowUpStory, promptUser: promptUserFollowUpStory });
        if (followUpStory && followUpStory.data?.story) {
            setFollowUpStoryResponse(followUpStory.data.story);
        }
    }
    const renderFollowUpStoryResponse = () => {
        if (!followUpStoryResponse) {
            return;
        }
        return <div className="api-response">
            <h4>Follow Up Story Response</h4>
            <div className="api-response-container">
                <p className="story-response">{followUpStoryResponse}</p>
            </div>
        </div>
    }

    return <>
        <div className="row">
            <div>
                <small>
                    This playground is designed to be a tool for Admins to test Google POIs, Websearch and Story Generation. Currently the Playground only generates Text Stories without Audio as a cost saving measure.
                    <br /><br />
                    The guided path is to test Surprise Me Stories. All you have to do is to press the Big Green Buttons in order from top to bottom.
                    <br /><br />
                    The tool can be used for other testing purposes as well. The admin has full control of what is being sent to the AI models as all of the fields are sent as they are without further processing. Admins may freely edit any of the fields.
                    <br /><br />
                    Changes made in the playground will not be saved to the host.
                </small>
            </div>
            <div></div>
        </div>
        <hr />
        <h2>Google POIs</h2>

        <div className="row row-wrap">
            <div>

                <MultiselectAccordionGooglePlacesTypes
                    onChange={handlePoiTypesChange}
                    selectedValues={selectedPoiTypes}
                />
                <LoadScript googleMapsApiKey={mapsApiKey}>
                    <GoogleMap
                        mapContainerStyle={{
                            width: "100%",
                            height: 600,
                            borderRadius: 8,
                            overflow: "hidden",
                        }}
                        onLoad={handleLoad}
                        onCenterChanged={handleCenterChanged}
                        center={currentLocation || defaultCenter}
                        zoom={10}
                    >
                    </GoogleMap>
                </LoadScript>
                <br />
                <LargeButton
                    text={"Search Google POIs"}
                    icon={null}
                    color={"#34A853"}
                    loading={searchGooglePOIsButtonLoading}
                    onClick={async function () {
                        setSearchGooglePOIsButtonLoading(true);
                        await searchGooglePOIs();
                        setSearchGooglePOIsButtonLoading(false);
                    }} />
            </div>
            <div>
                {renderGooglePOIsResponse()}
            </div>
        </div>

        <hr />
        <h2>(Optional) Augment POIs with Websearch</h2>
        <div className="row row-wrap">
            <div>
                <div>
                    <small>
                        Enter a template to use for the search. Following template
                        variables are available:{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}poi.name{"}"}
                        </span>
                        ,{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}poi.address{"}"}
                        </span>
                        ,{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}poi.city{"}"}
                        </span>
                        ,{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}poi.country{"}"}
                        </span>{" "}
                    </small>
                </div>

                <h3>Query</h3>
                <textarea
                    style={{
                        minHeight: 80,
                        overflow: "hidden",
                    }}
                    value={webSearchQuery}
                    placeholder="Example: Interesting facts about Red Hook, City of New York, New York, United States"
                    onChange={(e) => {
                        setWebSearchQuery(e.target.value);
                    }}
                ></textarea>

                <h3>Topic</h3>
                <Select
                    value={selectedWebSearchTopic}
                    onChange={(newValue) => {
                        setSelectedWebSearchTopic(newValue);
                    }}
                    options={webSearchTopicOptions()}
                    styles={dropdownStyles}
                />

                <h3>Include domains</h3>
                <small>
                    Enter a list of domains (urls) to include in the search.
                </small>
                <textarea
                    style={{
                        marginTop: 8,
                        minHeight: 45,
                        overflow: "hidden",
                    }}
                    value={includeDomains.join(" ")}
                    placeholder="example.com another.com"
                    onChange={(e) => {
                        setIncludeDomains(e.target.value.split(" "));
                    }}
                ></textarea>

                <h3>Exclude domains</h3>
                <small>
                    Enter a list of domains (urls) to exclude in the search.
                </small>
                <textarea
                    style={{
                        marginTop: 8,
                        minHeight: 45,
                        overflow: "hidden",
                    }}
                    value={excludeDomains.join(" ")}
                    placeholder="example.com another.com"
                    onChange={(e) => {
                        setExcludeDomains(e.target.value.split(" "));
                    }}
                ></textarea>
                <LargeButton
                    text={"Augment POIs with Websearch"}
                    icon={null}
                    color={"#34A853"}
                    disabled={!googlePOIsResponse || !googlePOIsResponse.length}
                    loading={loadingWebsearch}
                    onClick={async function () {
                        setLoadingWebsearch(true);
                        await doWebsearch();
                        setLoadingWebsearch(false);
                    }}
                    style={{ marginTop: 8 }}
                />
            </div>
            <div>
                {renderWebsearchResponse()}
            </div>
        </div>

        <hr />
        <h2>Story</h2>
        <div className="row row-wrap">
            <div>
                <h3>Apply POIs to Prompts</h3>
                <div className="side-by-side">
                    <LargeButton
                        text={"Websearch Augmented POIs"}
                        icon={null}
                        color={"#34A853"}
                        style={{ marginTop: 8 }}
                        disabled={!webSearchResponse}
                        onClick={applyAugmentedPOIsToPromptsStory}
                    />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>or</div>
                    <LargeButton
                        text={"Google POIs"}
                        icon={null}
                        color={"#34A853"}
                        style={{ marginTop: 8 }}
                        disabled={!googlePOIsResponse}
                        onClick={applyPOIstoPromptsStory}
                    />
                </div>
                <h3>System Prompt <small>("Persona Definition" Prompt)</small></h3>
                <div>
                    <small>
                        <b>
                            Your prompt should include any direction on what your host
                            is an expert in, what they should look for and focus on in
                            their stories, and how they should present and construct
                            their stories.
                        </b>
                        <br />
                        <br />
                        You can adjust the prompts to your liking. Be descriptive and
                        clear. You can also motivate the AI to do your bidding.
                        <br />
                        <br />
                        There are three variables in the system prompt:
                        <span style={{ color: "#34A853" }}>
                            {" {"}city{"}"}
                        </span>
                        ,{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}
                            country{"}"}
                        </span>{" "}
                        and{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}pois{"}"}
                        </span>
                        .
                        <br />
                        <br />
                        The{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}city{"}"}
                        </span>{" "}
                        and{" "}
                        <span style={{ color: "#34A853" }}>
                            {"{"}country{"}"}
                        </span>{" "}
                        variables give the AI some context about the location.
                    </small>
                </div>
                <br />
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptSystemStory}
                    onChange={(e) => {
                        setPromptSystemStory(e.currentTarget.value);
                    }}
                ></textarea>
                <h3>User Prompt <small>("Surprise Me" Prompt)</small></h3>
                <div>
                    <small>
                        This prompt is used along with the host prompt when the user
                        selects “Surprise me!” in the app. It guides the AI to find
                        interesting stories about a number of close by POIs.
                        <br />
                        <br />
                        Variables in the prompt:
                        <span style={{ color: "#34A853" }}>
                            {" {"}pois{"}"}
                        </span>
                    </small>
                </div>
                <br />
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptUserStory}
                    onChange={(e) => {
                        setPromptUserStory(e.currentTarget.value);
                    }}
                ></textarea>
                <LargeButton
                    text={"Generate Story"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!promptSystemStory || !promptUserStory}
                    loading={generateStoryButtonLoading}
                    onClick={async () => {
                        setGenerateStoryButtonLoading(true);
                        await doGenerateStory();
                        setGenerateStoryButtonLoading(false);
                    }} />
            </div>
            <div>{renderStoryResponse()}</div>
        </div>

        <hr />
        <h2>Follow Up Suggestions</h2>
        <div className="row row-wrap">
            <div>
                <LargeButton
                    text={"Apply Story to Follow Up Suggestions Prompts"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!storyResponse}
                    onClick={applyStoryToPromptsFollowUpSuggestions}
                />
                <h3>System Prompt <small>("Extract Rabbithole Extension Points" Prompt)</small></h3>
                <small>
                    This prompt is used to extract N amount of topics from the
                    generated story. The topics are then presented to the user as
                    the options for follow up stories.
                    <br />
                    <br />
                    Variables in the prompt:
                    <span style={{ color: "#34A853" }}>
                        {" {"}n{"}"}
                    </span>
                </small>
                <br />
                <br />
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptSystemFollowUpSuggestions}
                    onChange={(e) => {
                        setPromptSystemFollowUpSuggestions(e.currentTarget.value);
                    }}
                ></textarea>
                <h3>User Prompt <small>(Story Transcript)</small></h3>
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptUserFollowUpSuggestions}
                    onChange={(e) => {
                        setPromptUserFollowUpSuggestions(e.currentTarget.value);
                    }}
                ></textarea>
                <LargeButton
                    text={"Generate Follow Up Suggestions"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!promptSystemFollowUpSuggestions || !promptUserFollowUpSuggestions}
                    loading={generateFollowUpSuggestionsButtonLoading}
                    onClick={async () => {
                        setGenerateFollowUpSuggestionsButtonLoading(true);
                        await doGenerateFollowUpSuggestions();
                        setGenerateFollowUpSuggestionsButtonLoading(false);
                    }} />
            </div>
            <div>{renderFollowUpSuggestionsResponse()}</div>
        </div>

        <hr />
        <h2>Follow Up Story</h2>
        <div className="row row-wrap">
            <div>
                {followUpSuggestionsResponse && followUpSuggestionsResponse.length > 0 &&
                    <>
                        <h5>Apply Follow Up Suggestion to Prompts</h5>
                        <div className="side-by-side">
                            {
                                followUpSuggestionsResponse.map((suggestion, index) => {
                                    const separator = index < followUpSuggestionsResponse.length - 1 ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>or</div> : null;

                                    return <React.Fragment key={index}>
                                        <LargeButton
                                            text={suggestion}
                                            icon={null}
                                            color={"#34A853"}
                                            style={{ marginTop: 8 }}
                                            onClick={() => {
                                                applyFollowUpSuggestionToPrompts(suggestion);
                                            }}
                                        />
                                        {separator}
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </>
                }
                {!followUpSuggestionsResponse && (<LargeButton text={"Apply Follow Up Suggestion to Prompts"}
                    icon={null} color={"#34A853"} style={{ marginTop: 8 }}
                    disabled={true}
                    onClick={() => null}
                />)}
                <h3>System Prompt <small>(Story Transcript)</small></h3>
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptSystemFollowUpStory}
                    onChange={(e) => {
                        setPromptSystemFollowUpStory(e.currentTarget.value);
                    }}
                ></textarea>
                <h3>User Prompt <small>(Rabbithole Continuation Prompt)</small></h3>
                <div>
                    <small>
                        This prompt is used when the user selects a follow up topic
                        after the initial story is generated. This prompt guides the
                        AI to generate a story about a specific follow up topic that
                        is extracted from the previous story in the rabbit hole.
                        <br />
                        <br />
                        Variables in the prompt:
                        <span style={{ color: "#34A853" }}>
                            {" {"}follow_up_topic{"}"}
                        </span>
                    </small>
                </div>
                <br />
                <textarea
                    style={{ minHeight: 500 }}
                    value={promptUserFollowUpStory}
                    onChange={(e) => {
                        setPromptUserFollowUpStory(e.currentTarget.value);
                    }}
                ></textarea>
                <LargeButton
                    text={"Generate Follow Up Story"}
                    icon={null}
                    color={"#34A853"}
                    style={{ marginTop: 8 }}
                    disabled={!promptSystemFollowUpStory || !promptUserFollowUpStory}
                    loading={generateFollowUpStoryButtonLoading}
                    onClick={async () => {
                        setGenerateFollowUpStoryButtonLoading(true);
                        await doGenerateFollowUpStory();
                        setGenerateFollowUpStoryButtonLoading(false);
                    }} />
            </div>
            <div>{renderFollowUpStoryResponse()}</div>
        </div>
    </>

}

export default HostPlaygroundSurpriseMe;