import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";
import copy from "../assets/copy.png";
import {
  useApiPromoCodes,
  apiUpdatePromoCode,
  apiCreatePromoCode,
  apiDeletePromoCode,
  useApiPersonas,
} from "../api/api";
import "./PromoCodesPage.css";
import SmallButton from "../components/input/SmallButton";
import Modal from "../components/layout/Modal";
import { useState } from "react";
import { PromoCodeModel } from "../model/promocode";
import MultiselectAccordion from "../components/input/MultiselectAccordion";
import { MultiValue } from "react-select";
import { OptionType } from "./HostPage";
import LargeButton from "../components/input/LargeButton";
import addIcon from "../assets/add.png";

function PromoCodesPage() {
  const promoCodes = useApiPromoCodes();
  const personas = useApiPersonas();

  const getPersona = (personaId: number) => {
    if (!personas || !personas.data) {
      return null;
    }
    return personas.data.find((persona) => persona.id === personaId);
  };

  const getPersonaLink = (personaId: number, key: string | number) => {
    const persona = getPersona(personaId);
    if (!persona) {
      return null;
    }
    return (
      <a
        target="_BLANK"
        rel="noreferrer"
        href={`/hosts/${personaId}`}
        key={key}
      >
        {persona.name}
      </a>
    );
  };

  const personaChoices = () => {
    if (!personas || !personas.data) {
      return [];
    }
    return personas.data
      .filter(
        (persona) =>
          persona.stage === "prod" && persona.subscriptionType === "premium"
      )
      .map((persona) => ({
        label: persona.name,
        value: persona.id.toString(),
      }));
  };

  const deletePromoCode = (id: number) => {
    const isConfirmed = window.confirm(
      "***WARNING***\n\nYou are about to delete a Promo Code. This action cannot be undone. Are you sure you want to proceed?"
    );
    if (!isConfirmed) {
      return;
    }
    console.log("Deleting promo code with ID: ", id);
    apiDeletePromoCode(id).then(() => {
      promoCodes.refresh();
    });
  };

  const [id, setId] = useState(0);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPersonas, setSelectedPersonas] = useState<
    MultiValue<OptionType>
  >([]);
  const handlePersonasChange = (selected: MultiValue<OptionType>) => {
    setSelectedPersonas(selected);
    setPendingSave(true);
  };
  const [usageLimit, setUsageLimit] = useState(0);
  const [duration, setDuration] = useState(0);
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [pendingSave, setPendingSave] = useState(false);

  const openEditDialog = (promocode: PromoCodeModel) => {
    setId(promocode.id);
    setCode(promocode.code);

    const initialSelectedOptions = personaChoices().filter((option) =>
      promocode.personaIds.includes(parseInt(option.value))
    );
    setSelectedPersonas(initialSelectedOptions);

    setDescription(promocode.description || "");
    setUsageLimit(promocode.usageLimit);
    setDuration(promocode.duration);
    setExpirationDate(
      promocode.expirationDate ? new Date(promocode.expirationDate) : undefined
    );
    setShowEditDialog(true);
    setPendingSave(false);
  };

  const createNewPromoCode = () => {
    setId(0);
    setCode("");
    setSelectedPersonas([]);
    setDescription("");
    setUsageLimit(0);
    setDuration(30);
    setExpirationDate(undefined);
    setShowEditDialog(true);
    setPendingSave(false);
  };

  const savePromoCode = () => {
    if (!pendingSave) {
      return;
    }
    const personaIds = selectedPersonas.map((option) => parseInt(option.value));

    const _expirationDate = expirationDate
      ? new Date(expirationDate)
      : undefined;
    if (_expirationDate) {
      _expirationDate.setHours(23, 59, 59, 999);
    }
    if (id === 0) {
      apiCreatePromoCode({
        code,
        description,
        personaIds,
        usageLimit,
        duration,
        expirationDate: _expirationDate,
      }).then(() => {
        promoCodes.refresh();
        setPendingSave(false);
        setShowEditDialog(false);
      });
    } else {
      apiUpdatePromoCode({
        id,
        code,
        description,
        personaIds,
        usageLimit,
        duration,
        expirationDate: _expirationDate,
      }).then(() => {
        promoCodes.refresh();
        setPendingSave(false);
        setShowEditDialog(false);
      });
    }
  };

  const now = new Date();

  return (
    <div className="app">
      <HeaderBar>
        <img
          src={copy}
          alt="Promo Codes"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          Promo Codes
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        >
          <LargeButton
            text="Add new Promo Code"
            onClick={() => {
              createNewPromoCode();
            }}
            icon={addIcon}
            color={"#F0EDE0"}
          />
        </div>
      </HeaderBar>
      <NavBar selectedIndex={2} />

      <div className="content" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Description</th>
              <th>Personas</th>
              <th>Free Days</th>
              <th>Usage</th>
              <th>Expiration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(promoCodes.data?.promoCodes || []).map((promoCode) => (
              <tr
                key={promoCode.id}
                className={
                  promoCode.expirationDate &&
                  new Date(promoCode.expirationDate) < now
                    ? "expired"
                    : ""
                }
              >
                <td>{promoCode.code}</td>
                <td>{promoCode.description}</td>
                <td>
                  <div className="persona-links">
                    {promoCode.personaIds.map((personaId, i) =>
                      getPersonaLink(personaId, i)
                    )}
                  </div>
                </td>
                <td>{promoCode.duration}</td>
                <td>
                  {promoCode.usageLimit
                    ? `${promoCode.redeemCount}/${promoCode.usageLimit}`
                    : promoCode.redeemCount}
                </td>
                <td>
                  {promoCode.expirationDate
                    ? new Date(promoCode.expirationDate).toLocaleString()
                    : null}
                </td>
                <td>
                  <div className="btns">
                    <SmallButton
                      text="Edit"
                      icon={null}
                      isOutlineOnly={true}
                      onClick={() => openEditDialog(promoCode)}
                      className="promocode-btn"
                    />
                    <SmallButton
                      text="Delete"
                      icon={null}
                      isOutlineOnly={true}
                      onClick={() => deletePromoCode(promoCode.id)}
                      className="promocode-btn small-button-outline-danger"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={showEditDialog}
        onClose={() => {
          setShowEditDialog(false);
        }}
      >
        <h4>Code</h4>
        <input
          type="text"
          value={code}
          onChange={(value) => {
            setCode(value.currentTarget.value);
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            fontWeight: 600,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        <h4>Description</h4>
        <textarea
          value={description}
          onChange={(value) => {
            setDescription(value.currentTarget.value);
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        <MultiselectAccordion
          groups={[{ label: "Personas", options: personaChoices() }]}
          selectedValues={selectedPersonas}
          onChange={handlePersonasChange}
          defaultOpenSections={["Personas"]}
        />
        <h4>Free Days</h4>
        <small>How many days of Free use does the User receive.</small>
        <input
          type="number"
          value={duration}
          onChange={(value) => {
            setDuration(parseInt(value.currentTarget.value));
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        <h4>Usage Limit</h4>
        <small>
          How many users can redeem this code before it becomes unusable. Input
          0 for unlimited uses.
        </small>
        <input
          type="number"
          value={usageLimit}
          onChange={(value) => {
            setUsageLimit(parseInt(value.currentTarget.value));
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        <h4>Expiration Date</h4>
        <small>When does the code expire? You may leave this blank.</small>
        <input
          type="date"
          value={expirationDate?.toISOString().split("T")[0]}
          onChange={(value) => {
            setExpirationDate(
              value.currentTarget.value
                ? new Date(value.currentTarget.value)
                : undefined
            );
            setPendingSave(true);
          }}
          style={{
            fontSize: 22,
            outline: "none",
            marginTop: 8,
            boxSizing: "border-box",
            width: "100%",
            padding: 12,
          }}
        />
        <LargeButton
          style={{ marginTop: 16 }}
          text="Save"
          icon={null}
          color="#34A853"
          disabled={!pendingSave}
          onClick={() => {
            savePromoCode();
          }}
        />
      </Modal>
    </div>
  );
}
export default PromoCodesPage;
