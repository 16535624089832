import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <span className="loading">
        Loading
        <span>.</span>
        <span style={{ animationDelay: "0.2s" }}>.</span>
        <span style={{ animationDelay: "0.4s" }}>.</span>
      </span>
    </div>
  );
};

export default LoadingScreen;
